// 橘色中空按钮
.koala-ghost-btn {
  margin: 4px;
  text-align: center;
  background-color: #fff;
  color: #4273f6;
  border-color: #4273f6;

  &:focus {
    background-color: #fff;
  }

  &:hover {
    background-color: #4273f6;
    color: #fff;
    border-color: #4273f6;
  }
}

// 表格操作列按钮
.table-btn {
  .koala-ghost-btn();
}

// 查询页操作按钮
.operator-btn-wrapper {
  position: absolute;
  bottom: -40px;
  left: 15px;

  .operator-btn {
    margin: 0 5px;
  }
}

.disabledBtn {
  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
}

.footer-btn-wrapper {
  .footer-btn {
    margin: 0 5px;
    &:first {
      margin-left: 0;
    }
  }

  .footer-btn,
  .footer-btn:focus {
    color: #42485b;
    background-color: #fff;
    border-color: #42485b;

    .disabledBtn();
  }

  .footer-btn:hover {
    color: #ffffff;
    background-color: #42485b;
    border-color: #42485b;

    .disabledBtn();
  }
}
