.ant-layout-content {
  .customTabs {
    .ant-tabs-tab {
      border-bottom: 0 !important;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}
.header-button {
  margin: 0 10px;
}

@BaseColor: #4273F6;@primary-color: #4273F6;@label-color: #666666;@input-color: #333333;@input-placeholder-color: #999999;@input-disabled-color: #999999;@input-disabled-bg: #eeeeee;@table-header-bg: #f4f4f4;@select-item-selected-font-weight: 400;@select-item-selected-color: #4273F6;@table-font-size: 12px;@table-padding-vertical-sm: 3px;@table-padding-vertical-md: 5.25px;@table-padding-vertical: 7px;@table-padding-horizontal: 7px;@input-height-base: 34px;@descriptions-item-padding-bottom: 8px;@tooltip-bg: white;@tooltip-color: #333;