body,
#basic-layout {
  /*下拉框内容显示不全*/
}
body .ant-body,
#basic-layout .ant-body {
  position: relative;
}
body .ant-picker,
#basic-layout .ant-picker {
  width: 100%;
}
body .ant-input-number,
#basic-layout .ant-input-number {
  width: 100%;
}
body .ant-spin-spinning,
#basic-layout .ant-spin-spinning {
  width: 100%;
}
body .ant-select-item-option-content,
#basic-layout .ant-select-item-option-content {
  white-space: normal;
  word-break: break-all;
}
body .ant-table,
#basic-layout .ant-table {
  color: #666666;
}
body .ant-table-thead > tr > th,
#basic-layout .ant-table-thead > tr > th {
  font-weight: bold;
}
body .ant-table-pagination.ant-pagination,
#basic-layout .ant-table-pagination.ant-pagination {
  margin: 16px 15px;
}
body .ant-table-small th,
#basic-layout .ant-table-small th,
body body .ant-table-small th,
#basic-layout body .ant-table-small th {
  padding: 6px 0 !important;
}
body .ant-table-small td,
#basic-layout .ant-table-small td,
body body .ant-table-small td,
#basic-layout body .ant-table-small td {
  padding: 0 20px;
}
body .ant-table-tbody > tr.ant-table-row:hover > td,
#basic-layout .ant-table-tbody > tr.ant-table-row:hover > td,
body .ant-table-tbody > tr > td.ant-table-cell-row-hover,
#basic-layout .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #f0f4ff;
}
body .ant-descriptions-item-label,
#basic-layout .ant-descriptions-item-label {
  display: inline-block !important;
  color: #999999;
  text-align: right;
  width: 100px;
}
body .ant-descriptions-item-content,
#basic-layout .ant-descriptions-item-content {
  color: #333;
  width: 240px;
}
body .ant-descriptions-item,
#basic-layout .ant-descriptions-item {
  padding-bottom: 12px !important;
}
body .ant-drawer-close,
#basic-layout .ant-drawer-close {
  padding-left: 0;
}
body .ant-drawer-title,
#basic-layout .ant-drawer-title {
  color: #333;
}
body .ant-drawer-body,
#basic-layout .ant-drawer-body {
  padding-top: 0;
}
body .ant-modal-footer,
#basic-layout .ant-modal-footer {
  text-align: center;
}
body .ant-input-textarea-show-count.ant-input-textarea-in-form-item::after,
#basic-layout .ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  margin-bottom: -16px;
}
body .card-container p,
#basic-layout .card-container p {
  margin: 0;
}
body .card-container > .ant-tabs-card .ant-tabs-content,
#basic-layout .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
body .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane,
#basic-layout .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
}
body .card-container > .ant-tabs-card > .ant-tabs-nav::before,
#basic-layout .card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
body .card-container > .ant-tabs-card .ant-tabs-tab,
#basic-layout .card-container > .ant-tabs-card .ant-tabs-tab,
body [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab,
#basic-layout [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
body .card-container > .ant-tabs-card .ant-tabs-tab-active,
#basic-layout .card-container > .ant-tabs-card .ant-tabs-tab-active,
body [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active,
#basic-layout [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
body #components-tabs-demo-card-top .code-box-demo,
#basic-layout #components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
body [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content,
#basic-layout [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
body [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab,
#basic-layout [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
body [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo,
#basic-layout [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
body [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane,
#basic-layout [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
body [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active,
#basic-layout [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}
body .card-container .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
#basic-layout .card-container .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 13px 26px;
  font-weight: 500;
}
body .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
#basic-layout .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
body .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab,
#basic-layout .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 6px 6px 0 0;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}
.koala-ghost-btn {
  margin: 4px;
  text-align: center;
  background-color: #fff;
  color: #4273f6;
  border-color: #4273f6;
}
.koala-ghost-btn:focus {
  background-color: #fff;
}
.koala-ghost-btn:hover {
  background-color: #4273f6;
  color: #fff;
  border-color: #4273f6;
}
.table-btn {
  margin: 4px;
  text-align: center;
  background-color: #fff;
  color: #4273f6;
  border-color: #4273f6;
}
.table-btn:focus {
  background-color: #fff;
}
.table-btn:hover {
  background-color: #4273f6;
  color: #fff;
  border-color: #4273f6;
}
.operator-btn-wrapper {
  position: absolute;
  bottom: -40px;
  left: 15px;
}
.operator-btn-wrapper .operator-btn {
  margin: 0 5px;
}
.disabledBtn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.footer-btn-wrapper .footer-btn {
  margin: 0 5px;
}
.footer-btn-wrapper .footer-btn:first {
  margin-left: 0;
}
.footer-btn-wrapper .footer-btn,
.footer-btn-wrapper .footer-btn:focus {
  color: #42485b;
  background-color: #fff;
  border-color: #42485b;
}
.footer-btn-wrapper .footer-btn[disabled],
.footer-btn-wrapper .footer-btn:focus[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.footer-btn-wrapper .footer-btn:hover {
  color: #ffffff;
  background-color: #42485b;
  border-color: #42485b;
}
.footer-btn-wrapper .footer-btn:hover[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.chooseTag-wrapper {
  border: 1px solid #d9d9d9;
}
.chooseTag-wrapper .tag-wrapper {
  float: left;
  display: flex;
  flex-wrap: wrap;
  min-height: 32px;
  text-align: center;
  padding: 4px 5px;
}
.chooseTag-wrapper .tag-wrapper .ant-tag {
  margin: 3px;
}
.chooseTag-wrapper .addTag-wrapper {
  border: none;
  background-color: #f2f0f0;
  margin: 5px;
  box-sizing: border-box;
  height: 22px;
  width: 150px;
  display: flex;
}
.chooseTag-wrapper .addTag-wrapper .addTag-input {
  flex: 1;
  border: none;
  background-color: #f2f0f0;
  height: 22px;
}
.chooseTag-wrapper .addTag-wrapper .addTag-input:focus {
  border: none;
  box-shadow: none;
}
.chooseTag-wrapper .addTag-wrapper .addTag-btn {
  text-align: center;
  width: 35px;
  color: #4273f6;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.mar-top-10 {
  margin-top: 10px;
}
.mar-bottom-10 {
  margin-bottom: 10px;
}
.mar-left-10 {
  margin-left: 10px;
}
.mar-right-10 {
  margin-right: 10px;
}
.right-btn-container {
  display: flex;
  justify-content: flex-end;
}
.record-calculate {
  width: 800px;
  margin-top: 10px;
}
.limit-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  /*允许长单词换行到下一行*/
}
.text-color {
  color: #4273F6;
  cursor: pointer;
}
.table-position {
  z-index: 9 !important;
}
.form-two-left .ant-form-item-label {
  margin-left: 12px;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-small-margin {
  margin: 6px 0;
}
.search-width {
  width: 150px;
}
.scanWrapper {
  display: flex;
  justify-content: flex-end;
}
.clr:after {
  content: '\200B';
  display: block;
  height: 0;
  clear: both;
}
.clr {
  *zoom: 1;
}
.search-table-wrapper .ant-table .ant-table-cell-fix-left {
  position: static !important;
}
