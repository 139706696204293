// antd reset
body,
#basic-layout {
  .ant-body {
    position: relative;
  }

  // DatePicker
  // 组件宽度100%
  .ant-picker {
    width: 100%;
  }
  // inputNumber组件宽度100%
  .ant-input-number {
    width: 100%;
  }
  // spin组件居中
  .ant-spin-spinning {
    width: 100%;
  }

  // Select
  /*下拉框内容显示不全*/
  .ant-select-item-option-content {
    white-space: normal;
    word-break: break-all;
  }
  // 禁用效果去除透明度，避免在业务电脑上显示不清

  // Table
  .ant-table {
    color: #666666;
  }
  // 表头加粗
  .ant-table-thead > tr > th {
    font-weight: bold;
  }
  // 表格的分页组件外边距
  .ant-table-pagination.ant-pagination {
    margin: 16px 15px;
  }
  .ant-table-small th,
  body .ant-table-small th {
    padding: 6px 0 !important;
  }

  .ant-table-small td,
  body .ant-table-small td {
    padding: 0 20px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #f0f4ff;
  }

  // Description
  .ant-descriptions-item-label {
    display: inline-block !important;
    color: #999999;
    text-align: right;
    width: 100px;
  }
  .ant-descriptions-item-content {
    color: #333;
    width: 240px;
  }
  .ant-descriptions-item {
    padding-bottom: 12px !important;
  }

  // Drawer
  .ant-drawer-close {
    padding-left: 0;
  }
  .ant-drawer-title {
    color: #333;
  }
  .ant-drawer-body {
    padding-top: 0;
  }

  // Modal
  .ant-modal-footer {
    text-align: center;
  }

  // Form

  // Input
  .ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
    margin-bottom: -16px;
  }

  // Card
  .card-container p {
    margin: 0;
  }
  .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
  }
  .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
  }
  .card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .card-container > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
  }
  #components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
  }
  [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
  [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
  }
  [data-theme='dark']
    .card-container
    > .ant-tabs-card
    .ant-tabs-content
    > .ant-tabs-tabpane {
    background: #141414;
  }
  [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #141414;
    border-color: #141414;
  }
  .card-container {
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
      padding: 13px 26px;
      font-weight: 500;
    }
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 6px 6px 0 0;
  }
}
.ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}
