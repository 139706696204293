@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
// @import "~@ant-design/aliyun-theme/index.less";

// @import '~antd/dist/antd.dark.less'; // 引入官方提供的暗色 less 样式入口文件
// @import '~antd/dist/antd.compact.less'; // 引入官方提供的紧凑 less 样式入口文件
.suspense-box {
  padding-top: 20%;
  text-align: center;
}

.selector {
  color: @blue-5;
  background-color: @gold-2;
}

.ant-layout-sider,
.ant-layout {
  min-height: 100vh;
  overflow-x: hidden;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ant-input-number-handler-wrap {
  display: none;
}

.btn-flex {
  display: flex;
  // justify-self: start;
  // justify-content: center;
  align-content: space-around;
  // justify-content: space-around;
  justify-content: space-around;
  align-items: space-around;
  flex-wrap: wrap;
  a {
    padding: 0px 2px;
  }
}
.btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 16px;
}

.menu .anticon {
  margin-right: 8px;
}

.menu .ant-dropdown-menu-item {
  width: 160px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

.ant-table-tbody > tr > td {
  word-break: break-all !important;
}

.ant-descriptions-item-label {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
  line-height: 20px;
}
.drawer-btn {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
  border-top: 1px solid rgb(240, 240, 240);
}
.ehi-wrap {
  .logo {
    height: 32px;
    background: url(http://files.1hai.cn/group72/M00/46/EF/rBUFIFuiUheAeI4sAAAkH8LL6RY545.png?sign=ZjI1OGE2NjdkY2UxNTVjNWVhZGYyNDA0ZDk2NzUzNGQ%3D&visitType=ext)
      no-repeat center center;
    background-size: auto 90%;
    margin: 16px;
    padding-left: 0;
  }

  .logo-mobile {
    height: 32px;
    background: url(https://externalimage.1hai.cn/1001/d6565699a6914336b26b67457d60c35e.png)
      no-repeat center center;
    background-size: 26px;
    margin: 16px;
  }

  .ant-table-tbody > tr > td {
    word-break: break-all !important;
  }
  // .ant-back-top {
  //   right: 50px;
  // }
  // .ant-table-pagination {
  //   margin: 6px 0;
  // }

  // .ant-table-footer {
  //   padding: 3px;
  //   background-color: #fff;
  // }

  // .ant-btn {
  //   margin-right: 10px;
  // }

  // // color
  // .ehi-success {
  //   color: @success-color;
  // }

  // .ehi-error {
  //   color: @error-color;
  // }

  // .ehi-warning {
  //   color: @warning-color;
  // }

  // .ehi-primary {
  //   color: @primary-color;
  // }

  // // align
  // .ehi-tac {
  //   text-align: center;
  // }

  // .ehi-tar {
  //   text-align: right;
  // }

  // .ehi-tal {
  //   text-align: left;
  // }

  // // ui-flex
  // .ui-flex {
  //   display: flex;
  // }

  // .checkbox-hidden {
  //   display: none;
  // }

  // .ui-flex > .cell {
  //   flex: 1;
  //   position: relative;
  //   display: block;
  //   max-width: 100%;
  //   width: 0;
  // }

  // .ui-flex.align-center {
  //   align-items: center;
  // }

  // .ui-flex.align-start {
  //   align-items: flex-start;
  // }

  // .ui-flex.align-end {
  //   align-items: flex-end;
  // }

  // .ui-flex.align-stretch {
  //   align-items: stretch;
  // }

  // /* ui-flex-colum */
  // .ui-flex-colum-wrap {
  //   display: flex;
  //   flex-wrap: wrap;
  //   flex-flow: column wrap;
  //   flex-direction: row;
  // }

  // .ui-flex-colum-wrap > .cell {
  //   position: relative;
  //   display: block;
  // }

  // .ui-flex-colum-wrap.align-center {
  //   align-items: center;
  // }

  // .ui-flex-colum-wrap.align-start {
  //   align-items: flex-start;
  // }

  // .ui-flex-colum-wrap.align-end {
  //   align-items: flex-end;
  // }

  // .ui-flex-colum-wrap.align-stretch {
  //   align-items: stretch;
  // }

  // .ui-flex-colum-wrap.justify-start {
  //   justify-content: flex-start;
  // }

  // .ui-flex-colum-wrap.justify-center {
  //   justify-content: center;
  // }

  // .ui-flex-colum-wrap.justify-end {
  //   justify-content: flex-end;
  // }

  // // 查询form
  // // .search-form {
  // //   .ant-form-item {
  // //     margin: 0 8px;
  // //     display: flex;
  // //     height: 48px;
  // //     &.search-btn {
  // //       .ant-form-item-label {
  // //         width: 20px;
  // //         min-width: auto;
  // //       }
  // //     }
  // //     // .ant-form-item-label {
  // //     //   min-width: 78px;
  // //     // }
  // //     // .ant-form-item-control {
  // //     //   .ant-select,
  // //     //   .ant-input {
  // //     //     min-width: 150px;
  // //     //   }
  // //     // }
  // //   }
  // //   padding: 8px 0;
  // //   margin-bottom: 12px;
  // // }

  // .ant-table-cell {
  //   text-align: center;
  // }
  // .ant-form-item-no-colon {
  //   // font-weight: 600;
  //   font-size: 14px;
  //   // line-height: 1.5;
  //   cursor: default;
  // }
}

@BaseColor: #4273F6;@primary-color: #4273F6;@label-color: #666666;@input-color: #333333;@input-placeholder-color: #999999;@input-disabled-color: #999999;@input-disabled-bg: #eeeeee;@table-header-bg: #f4f4f4;@select-item-selected-font-weight: 400;@select-item-selected-color: #4273F6;@table-font-size: 12px;@table-padding-vertical-sm: 3px;@table-padding-vertical-md: 5.25px;@table-padding-vertical: 7px;@table-padding-horizontal: 7px;@input-height-base: 34px;@descriptions-item-padding-bottom: 8px;@tooltip-bg: white;@tooltip-color: #333;