// 和弹框联动的标签样式
.chooseTag-wrapper {
  border: 1px solid #d9d9d9;
  .tag-wrapper {
    float: left;
    display: flex;
    flex-wrap: wrap;
    min-height: 32px;
    text-align: center;
    padding: 4px 5px;
    .ant-tag {
      margin: 3px;
    }
  }
  .addTag-wrapper {
    border: none;
    background-color: #f2f0f0;
    margin: 5px;
    box-sizing: border-box;
    height: 22px;
    width: 150px;
    display: flex;
    .addTag-input {
      flex: 1;
      border: none;
      background-color: #f2f0f0;
      height: 22px;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .addTag-btn {
      text-align: center;
      width: 35px;
      color: #4273f6;
      cursor: pointer;
    }
  }
}

.text-center {
  text-align: center;
}
.mar-top-10 {
  margin-top: 10px;
}
.mar-bottom-10 {
  margin-bottom: 10px;
}
.mar-left-10 {
  margin-left: 10px;
}
.mar-right-10 {
  margin-right: 10px;
}

.right-btn-container{
  display: flex;
  justify-content: flex-end;
}

.record-calculate{
  width: 800px;
  margin-top: 10px;
}

// 限制内容的展示
.limit-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word; /*允许长单词换行到下一行*/
}

.text-color{
  color: #4273F6;
  cursor: pointer;
}

.table-position{
  z-index: 9 !important;
}

.form-two-left{
  .ant-form-item-label{
    margin-left: 12px;
  }
}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-small-margin{
  margin: 6px 0;
}

.search-width{
    width: 150px;
}

.scanWrapper {
  display: flex;
  justify-content: flex-end;
}